
import MessageForm from "~/components/v2/message/message-form.vue";
import { mdiClose } from "@mdi/js";

export default {
  components: {
    MessageForm,
  },

  props: {
    profile: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,

      mdiClose,
    };
  },

  computed: {
    dialogProps() {
      if (
        this.$device.isMobile ||
        (typeof window !== "undefined" && window.innerWidth < 993)
      ) {
        return {
          scrollable: true,
          transition: "dialog-bottom-transition",
          contentClass: "message-dialog__content",
        };
      } else {
        return {
          maxWidth: 441,
          transition: "dialog-transition",
          contentClass: "message-dialog__content",
        };
      }
    },

    recipientStr() {
      const { name, age, location, myDetails, city } = this.profile || {};

      return [name, age || myDetails?.[0]?.age, location?.[0]?.baseCity || city]
        .filter(Boolean)
        .join(", ");
    },
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.$refs.MessageForm.clear();
      this.isOpen = false;
    },

    menuStateCallback(val) {
      if (val === false) {
        this.$refs.MessageForm.clear();
      }
    },
  },
};
