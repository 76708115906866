
export default {
  props: {
    profileId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isValid: false,
      loading: false,

      form: {
        profile_id: null,
        text: "",
      },

      textRules: [(v) => !!v || this.$t("validations.field-is-required")],
    };
  },

  methods: {
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    close() {
      this.clear();
      this.$emit("close");
    },

    async sendMessage() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.form.profile_id = this.profileId;

        try {
          const response = await this.$axios.post(
            "/api/messages/add",
            this.form
          );
          if (response.status === 200) {
            this.$notify({
              type: "success",
              message: this.$t("message-sent-successfully"),
            });
            this.close();
          }
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "error",
            message: this.$t("something-went-wrong"),
          });
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
