import { render, staticRenderFns } from "./message-dialog.vue?vue&type=template&id=31cbf3fb"
import script from "./message-dialog.vue?vue&type=script&lang=js"
export * from "./message-dialog.vue?vue&type=script&lang=js"
import style0 from "./message-dialog.vue?vue&type=style&index=0&id=31cbf3fb&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMessage: require('/app/components/icons/IconMessage.vue').default,VSpacer: require('/app/node_modules/vuetify/lib/components/VGrid/VSpacer.js').default,VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,VBtn: require('/app/node_modules/vuetify/lib/components/VBtn/VBtn.js').default,VCard: require('/app/node_modules/vuetify/lib/components/VCard/VCard.js').default,VDialog: require('/app/node_modules/vuetify/lib/components/VDialog/VDialog.js').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VDialog,VIcon,VSpacer})
